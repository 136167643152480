<template>
  <div id="app" class="app-content">
    <router-view></router-view>
    <Tabbar v-show="isShowBar"/>
  </div>
</template>
<script>

import Tabbar from "./views/Tabbar.vue";

export default {
  name: 'App',
  data() {
    return {
        isShowBar: true,
    };
  },
  components: {
    Tabbar
  },
  beforeCreate(){
    const domain = window.location.host;
    if(domain=='m.jingzong.net'){
        window.location.href = "https://h5.jingzong.net"+window.location.pathname;
    }
    if(domain=='m.jingzong.org'){
        window.location.href = "https://h5.jingzong.net"+window.location.pathname;
    }
  },
  mounted () {
    // this.$dialog.alert({
    //   title:'请保存并使用新地址',
    //   message: '<p><br/><a href="https://h5.jingzong.net">https://h5.jingzong.net</a><br/><br/>旧地址于10月1日停止使用，感恩您！</p>',
    //   allowHtml:true,
    //   confirmButtonText:'复制并关闭'
    // }).then(() => {
    //   this.$copyText('https://h5.jingzong.net').then(function (e) {
    //     console.log(e)
    //   }, function (e) {
    //     console.log(e)
    //   })
    // });
  },
  created() {
    console.log(this.$route.name);
    if(this.$route.name == 'SLive' 
    || this.$route.name == 'SDownload' 
    || this.$route.name == 'Birthday' 
    || this.$route.name == 'SLiveVt'
    || this.$route.name == 'Special'
    || this.$route.name == 'Daoying'){
      this.isShowBar = false;
    }
  },
  methods: {
  },
  metaInfo: {
      title: '澳洲淨宗學院',
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0' }
      ]
  }
}
</script>
<style  scoped>
.app-content {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
}
.v-list{
  margin: 20px 10px 80px 10px;
}
.v-item{
  margin-bottom: 10px;
}
.v-item-desc{
  margin: 5px 0px;
}
.v-item-title{
  font-size: 14px;
  line-height: 30px;
  padding-right: 5px;
  color: #323233;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.v-item-date{
  font-size: 12px;
  color: #969799;
}
.v-item-img{
  width: 100%;
  height:95px;
    object-fit: cover;
}


</style>
